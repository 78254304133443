::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-gray-5);
}

::-webkit-scrollbar-thumb {
  background: var(--color-gray-6);
  border-radius: 10px;
}
