*,
*:before,
*:after {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  overflow-x: hidden;
  font: var(--font-primary);
}

img {
  display: inline-block;
  height: auto;
  outline: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

blockquote {
  margin: 0;
}

menu,
nav {
  ul,
  li {
    margin: 0;
    padding: 0;
  }
}

hr {
  width: 100%;
  height: 20px;
  margin: 0 0 20px;
  border: none;
  border-bottom: 1px dashed;
}

button {
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  transition: all ease 300ms;
}
