.field--dropdown {
  p-dropdown {
    .p-dropdown-panel {
      top: 40px !important;
    }
  }

  &:focus {
    &.field--modal .p-float-label > .title-float {
      color: var(--color-primary);
    }
  }

  &:hover {
    &.field--modal .p-float-label > .title-float {
      color: var(--color-gray-3);
    }
  }
}

p-dropdown {

  .p-dropdown {
    width: 100%;
    height: 48px;
    background: var(--color-gray-5);
    color: var(--color-gray-1);
    border-radius: 8px;
    border: 1px solid var(--color-gray-4);

    &.large {
      height: 56px;
    }

    &:not(.p-disabled):hover {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    .p-dropdown-filter-container {
      height: 32px;

      .p-dropdown-filter {
        height: 32px;
      }
    }

    .p-dropdown-panel {
      margin-top: 4px;
    }

    .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
      outline: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: var(--color-gray-1) !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: transparent;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      font-size: 14px;
      color: var(--color-gray-4);
      line-height: 16px;
      border: none;
      border-radius: 8px;
      background-color: var(--color-gray-5);
      padding: 12px 16px;
      outline: none;
      box-shadow: none;
    }

    .p-dropdown-label {
      font-size: 14px;
      line-height: 34px;
      color: var(--color-gray-1);
      flex: 1 100%;
      width: 100%;
      padding: 8px 16px;
    }

    .p-dropdown-clear-icon {
      display: none;
    }
  }

  &.dropdown--small {
    .p-dropdown {
      .p-dropdown-label {
        font-size: 14px;
        line-height: 16px;
        color: var(--color-gray-1);
        padding: 8px 16px;
      }
    }
  }

  &.p-inputwrapper-focus {
    & + .title-float {
      color: var(--color-primary);
    }
  }
}

/* Input calendar */
.field__item.field__calendar {
  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: var(--color-error);
  }

  .p-float-label > .ng-invalid.ng-dirty + label {
    color: var(--color-error);
  }

  .p-inputtext:enabled:focus {
    border: 1px solid var(--color-gray-3);
    box-shadow: none;
  }

  .p-float-label input:focus ~ .title-float,
  .p-float-label input.p-filled ~ .title-float,
  .p-float-label textarea:focus ~ .title-float,
  .p-float-label textarea.p-filled ~ .title-float,
  .p-float-label .p-inputwrapper-focus ~ .title-float,
  .p-float-label .p-inputwrapper-filled ~ .title-float {
    top: -0.2rem;
    font-size: 12px;
    padding: 5px 7px;
    left: 12px;
  }
}


/* Input multiselect */
p-multiselect {
  &.p-multiselect {
    border: none;
    width: 100%;
    background: transparent;


    .p-multiselect {
      width: 100%;
      height: 48px;
      padding: 8px 16px;
      background: transparent;
      color: var(--color-gray-3);
      border-radius: 8px;
      border: 1px solid var(--color-gray-4);
      border-color: var(--color-gray-4);
      cursor: pointer;

      &:not(.p-disabled):hover {
        border: 1px solid var(--color-gray-3);
      }

      .p-multiselect-header {

        .p-multiselect-filter {
          height: 32px;
          width: 100%;
          background: var(--color-gray-55);
          border: none;
        }

        .p-multiselect-filter-icon {
          right: 0.8rem;
        }

        .p-multiselect-close {
          display: none;
          position: absolute;

          .p-multiselect-close-icon {
            position: absolute;
            right: 2.8rem;
          }
        }

        .p-multiselect-close:enabled:hover {
          color: var(--color-gray-3);
          background: var(--color-gray-5);
        }

        .p-multiselect-header .p-multiselect-close:enabled:focus {
          box-shadow: none;
        }
      }
    }

    .p-multiselect-panel {
      margin-top: 18px;
    }

    .p-multiselect-panel.p-component {
      top: 37px !important;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
      box-shadow: none;
    }
  }

  .p-multiselect-trigger.ng-tns-c170-11 {
    margin-right: -16px;
    color: var(--color-primary);
  }

  .p-checkbox {
    margin-right: 18px;

    .p-checkbox-box {
      background: transparent;
      border: 1px solid var(--color-gray-3);
    }
  }
}

.p-dropdown-panel.p-component,
.p-multiselect-panel.p-component {
  color: var(--color-gray-3);
  background: var(--color-gray-55);
  margin-top: 10px;
  box-shadow: 0px 10px 20px var(--color-shadow-5);
  border-radius: 8px;
}

.p-multiselect-panel.p-component .p-multiselect-items .p-multiselect-item {
  color: var(--color-gray-3);
}

.p-dropdown-panel.p-component > .p-dropdown-header,
.p-multiselect-panel.p-component > .p-multiselect-header {
  color: var(--color-gray-3);
  background: var(--color-gray-6);
  padding: 15px 30px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.p-multiselect-panel.p-component {
  .p-multiselect-items {
    padding: 8px 13px;

    .p-multiselect-item {
      border-radius: 8px;
    }

    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
    }
  }
}

.field__item.field--dropdown {
  &.has-error {
    p-dropdown {
      .p-dropdown {
        border: 1px solid var(--color-error);

        .p-inputtext {
          border: none;
        }

        &:hover {
          border: 1px solid var(--color-gray-3);

          .p-inputtext {
            border: none;
          }
        }
      }
    }

    .p-float-label {
      margin-bottom: 27px;
    }

    &:hover {
      .p-dropdown {
        border: 1px solid var(--color-gray-3);
      }
    }

    .field__item--error {
      &::after {
        right: 36px;
      }
    }
  }

  .p-float-label input:focus ~ .title-float,
  .p-float-label input.p-filled ~ .title-float,
  .p-float-label textarea:focus ~ .title-float,
  .p-float-label textarea.p-filled ~ .title-float,
  .p-float-label .p-inputwrapper-focus ~ .title-float,
  .p-float-label .p-inputwrapper-filled ~ .title-float {
    top: -0.2rem;
    font-size: 12px;
    padding: 5px 7px;
    left: 12px;
  }
}

/*THEME DEFAULT*/

.field__item.has-error {
  p-dropdown.dark, p-dropdown.light {
    .p-dropdown.has-error.ng-dirty.ng-invalid > .p-dropdown {
      border: 1px solid var(--color-error) !important;
    }
  }
}

p-dropdown {
  .p-dropdown {
    width: 100%;
    background: var(--color-gray-5);
    color: var(--color-gray-1);
    border-radius: 8px;
    border: 1px solid var(--color-gray-4);

    &:not(.p-disabled):hover {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    .p-dropdown-filter-container {
      height: 32px;

      .p-dropdown-filter {
        height: 32px;
      }
    }

    .p-dropdown-panel {
      margin-top: 4px;
    }

    .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
      outline: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: var(--color-gray-1) !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: transparent;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      font-size: 16px;
      color: var(--color-gray-4);
      line-height: 16px;
      border: none;
      border-radius: 8px;
      background-color: var(--color-gray-5);
      padding: 12px 16px;
      outline: none;
      box-shadow: none;
    }

    .p-dropdown-label {
      font-size: 16px;
      line-height: 34px;
      color: var(--color-gray-1);
    }

    .p-dropdown-clear-icon {
      display: none;
    }
  }

  &.dropdown--small {
    .p-dropdown {
      .p-dropdown-label {
        font-size: 14px;
        line-height: 16px;
        color: var(--color-gray-1);
        padding: 8px 16px;
      }
    }
  }

  &.p-inputwrapper-focus {
    & + .title-float {
      color: var(--color-primary);
    }
  }
}

.p-dropdown-panel {
  background: var(--color-gray-5) !important;
  padding: 2px 0;

  .p-dropdown-items-wrapper {
    background: var(--color-gray-5) !important;
    border-radius: 8px;
    max-height: 158px !important;
  }

  .p-dropdown-items .p-dropdown-item {
    color: var(--color-gray-1);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: var(--color-gray-5) !important;

    &.highlight, &.p-highlight {
      background: var(--color-gray-55) !important;
      color: var(--color-gray-1) !important;
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      background: var(--color-gray-55) !important;
      color: var(--color-gray-1) !important;
    }
  }
}

/*THEME LIGHT*/

p-dropdown.light {
  .p-dropdown {
    width: 100%;
    background: transparent;
    color: var(--color-gray-1);
    border: 1px solid var(--color-gray-4);

    &:not(.p-disabled):hover {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
      outline: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: var(--color-gray-1) !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: transparent;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      color: var(--color-gray-4);
      background-color: var(--color-gray-5);
    }

    .p-dropdown-label {
      color: var(--color-gray-1);
    }
  }

  &.dropdown--small {
    .p-dropdown {
      .p-dropdown-label {
        color: var(--color-gray-1);
      }
    }
  }

  &.p-inputwrapper-focus {
    & + .title-float {
      color: var(--color-primary);
    }
  }
}

.light.p-dropdown-panel {
  background: var(--color-gray-5) !important;
  padding: 2px 0;

  .p-dropdown-items-wrapper {
    background: var(--color-gray-5) !important;
    border-radius: 8px;
    max-height: 158px !important;
  }

  .p-dropdown-items .p-dropdown-item {
    color: var(--color-gray-1);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: var(--color-gray-5) !important;

    &.highlight, &.p-highlight {
      background: var(--color-gray-55) !important;
      color: var(--color-gray-1) !important;
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      background: var(--color-gray-55) !important;
      color: var(--color-gray-1) !important;
    }
  }
}

/*THEME DARK*/

p-dropdown.dark {
  .p-dropdown {
    width: 100%;
    background: transparent;
    color: var(--color-gray-1);
    border: 1px solid var(--color-gray-4);

    &:not(.p-disabled):hover {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-3);
      background: var(--color-gray-6);
      outline: none;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: var(--color-gray-1) !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: transparent;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      color: var(--color-gray-4);
      background-color: var(--color-gray-5);
    }

    .p-dropdown-label {
      color: var(--color-gray-1);
    }
  }

  &.dropdown--small {
    .p-dropdown {
      .p-dropdown-label {
        color: var(--color-gray-1);
      }
    }
  }

  &.p-inputwrapper-focus {
    & + .title-float {
      color: var(--color-primary);
    }
  }
}

.dark.p-dropdown-panel {
  background: var(--color-gray-55) !important;
  padding: 2px 0;

  .p-dropdown-items-wrapper {
    background: var(--color-gray-55) !important;
    border-radius: 8px;
    max-height: 158px !important;
  }

  .p-dropdown-items .p-dropdown-item {
    color: var(--color-gray-1);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: var(--color-gray-55) !important;

    &:hover {
      background: var(--color-gray-6) !important;
      color: var(--color-gray-1) !important;
    }

    &:not(.p-highlight):not(.p-disabled):hover {
      background: var(--color-gray-6) !important;
      color: var(--color-gray-1) !important;
    }
  }
}

/* Dropdown */
.dropdown__list {
  position: absolute;
  background: var(--color-gray-6);
  max-height: 158px;
  width: 100%;
  display: none;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px var(--color-shadow-3);
  z-index: 999;

  .dropdown__items {
    border: 1px solid var(--color-gray-55);
    color: var(--color-gray-1);
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
}

p-dropdown {
  .p-paginator-rpp-options.p-dropdown.p-component {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    max-width: 56px;
    background: var(--color-gray-6);
    border: 0.5px solid var(--color-gray-3);
    border-radius: 4px;
    margin: 0px;

    .p-dropdown-panel {
      top: 32px !important;
      background: var(--color-gray-5);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      color: var(--color-gray-1) !important;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: var(--color-gray-1);
      background: var(--color-gray-6);
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: var(--color-gray-1);
      background: var(--color-gray-55);
    }

    &:not(.p-disabled):hover {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    &:not(.p-disabled).p-focus {
      border: 1px solid var(--color-gray-3);
      box-shadow: none;
    }

    .p-dropdown-trigger {
      width: 20px;
    }

    .p-dropdown-label {
      font-family: var(--font-primary);
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-gray-1);
      padding: 10px 0px 8px 8px;
      margin: 0px;
    }

    .pi.pi-chevron-down {
      &:before {
        display: none;
      }

      &:after {
        content: url('../../assets/icon/caret-down-solid.svg');
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        width: 10px;
        height: 16px;
      }
    }
  }
}

/* Input dropdown */
.p-dropdown-trigger-icon {
  color: var(--color-primary);
}

.dropdown-shift {
  .p-dropdown .p-dropdown-panel {
    margin-top: 4px;
  }
}
