////
/// Functions
/// @group Mixins
/// @author Ignacio Rodrigues
////

//// Breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints

@mixin for-breakpoint($breakpoints) {
  @if $breakpoints == mobile-xs {
    @media (min-width: 320px) {
      @content;
    }
    // min-width: 320px
  } @else if $breakpoints == mobile {
    @media (min-width: 480px) {
      @content;
    }
    // min-width: 480px
  } @else if $breakpoints == ipad {
    @media (min-width: 765px) {
      @content;
    }
    // min-width: 768px
  } @else if $breakpoints == desktop {
    @media (min-width: 1024px) {
      @content;
    }
    // min-width: 1024px
  } @else if $breakpoints == desktop-hd {
    @media (min-width: 1440px) {
      @content;
    }
    // min-width: 1440px
  } @else if $breakpoints == desktop-xhd {
    @media (min-width: 1279px) {
      @content;
    }
    // min-width: 1658px
  } @else if $breakpoints == desktop-xxhd {
    @media (min-width: 1727px) {
      @content;
    }
    // min-width: 1658px
  } @else if $breakpoints == desktop-xxxhd {
    @media (min-width: 2559px) {
      @content;
    }
    // min-width: 1658px
  }
}

@mixin breakpoint($breakpoints) {
  @if $breakpoints == mobile-s {
    @media (min-width: 320px) {
      @content;
    }
  }
  @if $breakpoints == mobile-m {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $breakpoints == mobile-l {
    @media (min-width: 425px) {
      @content;
    }
  } @else if $breakpoints == ipad {
    @media (min-width: 765px) {
      @content;
    }
  } @else if $breakpoints == desktop-s {
    @media (min-width: 1024px) {
      @content;
    }
    // min-width: 1024px
  } @else if $breakpoints == desktop-m {
    @media (min-width: 1440px) {
      @content;
    }
    // min-width: 1440px
  } @else if $breakpoints == desktop-l{
    @media (min-width: 1279px) {
      @content;
    }
    // min-width: 1658px
  } @else if $breakpoints == desktop-xl {
    @media (min-width: 1727px) {
      @content;
    }
    // min-width: 1658px
  } @else if $breakpoints == desktop-xxl {
    @media (min-width: 2559px) {
      @content;
    }
    // min-width: 1658px
  }
}

@mixin custom-breakpoint($breakpoints) {
  @media (min-width: $breakpoints) {
    @content;
  }
}

// How to use mixin, add type breakpoint inside mixin for-breakpoint
// @include for-breakpoint('ipad') {}

// Skeleton background animation

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 0px 195px;
  }
}

@mixin skeleton-light {
  background: linear-gradient(0.25turn, transparent, var( --color-gray-5), transparent),
  linear-gradient(var(--color-gray-5), var(--color-gray-5)),
  radial-gradient(38px circle at 19px 19px, var(--color-gray-5) 50%, transparent 51%),
  linear-gradient(var(--color-gray-5), var(--color-gray-5));
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}

@mixin skeleton {
  background: linear-gradient(0.25turn, transparent, var(--color-gray-dark), transparent),
  linear-gradient(var(--color-gray-55), var(--color-gray-55)),
  radial-gradient(38px circle at 19px 19px, var(--color-gray-55) 50%, transparent 51%),
  linear-gradient(var(--color-gray-55), var(--color-gray-55));
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}

@mixin skeleton-green {
  background: linear-gradient(0.25turn, transparent, var(--color-primary), transparent),
  linear-gradient(var(--color-primary-disabled), var(--color-primary-disabled)),
  radial-gradient(38px circle at 19px 19px, var(--color-primary-disabled) 50%, transparent 51%),
  linear-gradient(var(--color-primary-disabled), var(--color-primary-disabled));
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}

@mixin skeleton-uala {
  background: linear-gradient(0.25turn, transparent, var( -color-uala), transparent),
  linear-gradient(var(--color-uala-2), var(--color-uala-2)),
  radial-gradient(38px circle at 19px 19px, var(--color-uala) 50%, transparent 51%),
  linear-gradient(var(--color-uala-2), var(--color-uala-2));
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  will-change: transform;
  animation: loading 1.6s linear infinite;
}
