.field-checkbox {
  margin-bottom: 24px;

  &.check-admin {
    flex-direction: column;
    align-items: start !important;

    .p-checkbox-label {
      font-family: var(--font-secondary);
      margin-left: 16px !important;
      font-weight: 600;
    }
  }

  .p-checkbox-label {
    color: var(--color-gray-3);
    margin-bottom: 0;
  }
}

.p-checkbox.p-component {
  .p-checkbox-box {
    background: transparent;
    border-radius: 2px;
    border: 1px solid var(--color-gray-3);

    &.p-focus {
      border: 1px solid var(--color-primary) !important;
      box-shadow: none !important;
    }

    &.p-highlight {
      background: var(--color-primary);
      border: 1px solid var(--color-primary);

      &.p-focus {
        border: 1px solid var(--color-primary);
      }

      .p-checkbox-icon.pi.pi-check {
        color: var(--color-gray-5);
        font-weight: 600;
      }
    }

    &:hover {
      border: 1px solid var(--color-gray-4);
    }

  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-highlight:hover {
      border: 1px solid var(--color-primary);
      background-color: var(--color-primary);
    }

    .p-checkbox-box.p-highlight:focus {
      border: 1px solid var(--color-primary);
    }

    .p-checkbox-box:hover {
      border: 1px solid var(--color-primary);
    }

    .p-checkbox-box:focus {
      border: 1px solid var(--color-primary);
    }
  }

  &.p-checkbox-focused {
    .p-checkbox-box {
      border: 1px solid var(--color-primary);
    }
  }
}

.checkbox_rounded {
  .p-checkbox-box {
    border-radius: 50% !important;
  }
}

.field-checkbox > .p-checkbox.p-component > .p-checkbox-box.p-highlight:hover {
  background: var(--color-primary);
}

.field__item {
  &.checkbox__legal {
    &.has-error {
      .p-checkbox.p-component .p-checkbox-box {
        border: 1px solid var(--color-error);
      }
    }
  }
}
