:root {
  --swiper-pagination-bullet-inactive-color: var(--color-gray-45);
  --swiper-pagination-color: var(--color-primary);
  --swiper-pagination-bullet-height: 8px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 20px;
}

.slideshow__events--mobile.swiper,
.carrousel__events.swiper,
.swiper__events.swiper {
  margin-right: 0;
  margin-left: 0;

  .swiper-slide {
    width: auto;
  }
}

.carrousel__events.swiper .swiper-wrapper {
  padding: 24px 16px 16px;

  &:after {
    content: " ";
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    width: 32px;
    height: 98%;
    background: transparent;
    mix-blend-mode: normal;
    opacity: 0.9;
  }

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 32px;
    height: 98%;
    background: transparent;
    mix-blend-mode: normal;
    opacity: 0.9;
    transform: rotate(-180deg);
  }

  @include custom-breakpoint('1279px') { // breakpoint 1280px
    padding-left: 0px;
  }
}
