@keyframes modalAnimation {
  0% {
    top: 100%;
  }
  100% {
    top: 0px;
  }
}

@keyframes modalAnimationClose {
  0% {
    top: 0px;
  }
  100% {
    top: 100%;
    display: none;
  }
}

@keyframes opacityVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes modalDownloadAnimation {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes modalDownloadAnimationClose {
  0% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: -100%;
    opacity: 0;
  }
}
