// INTER FONT
@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../../assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// POPPINS FONT
@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

// RUBIK FONT
@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// QUOSM FONT
@font-face {
  font-family: "Quosm";
  src: url("../../assets/fonts/quosm/Quosm.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Quosm";
  src: url("../../assets/fonts/quosm/Quosm-Semi-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Quosm";
  src: url("../../assets/fonts/quosm/Quosm-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

// OSWALD
@font-face {
  font-family: "Oswald";
  src: url("../../assets/fonts/oswald/Oswald-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../../assets/fonts/oswald/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../../assets/fonts/oswald/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../../assets/fonts/oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}


:root {
  --fs-10: 10px;
  --fs-12: 12px;
  --fs-14: 14px;
  --fs-16: 16px;
  --fs-18: 18px;
  --fs-20: 20px;
  --fs-22: 22px;
  --fs-24: 24px;
  --fs-26: 26px;
  --fs-28: 28px;
  --fs-32: 32px;
  --fs-40: 40px;
  --fs-48: 48px;
  --fs-52: 52px;
  --fs-56: 56px;
  --fs-60: 60px;
  --fs-72: 72px;
  --fs-96: 96px;

  --lh-0: 0px;
  --lh-8: 8px;
  --lh-10: 10px;
  --lh-12: 12px;
  --lh-14: 14px;
  --lh-16: 16px;
  --lh-18: 18px;
  --lh-20: 20px;
  --lh-22: 22px;
  --lh-24: 24px;
  --lh-26: 26px;
  --lh-28: 28px;
  --lh-30: 30px;
  --lh-32: 32px;
  --lh-34: 34px;
  --lh-40: 40px;
  --lh-80: 80px;

  --fw-100: 100;
  --fw-200: 200;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Poppins", sans-serif;
  --font-family: "Inter", sans-serif;
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

body {
  font-family: var(--font-primary);
  font-weight: var(--fw-400);
  background: var(--color-gray-55);
}

h1, h2, h3, h4, h5, h6, p, span, a, div, td, tr, button {
  font-family: var(--font-primary);;
  font-weight: var(--fw-400);
}

.text-xxs {
  font-size: var(--fs-10) !important;
}

.text-xs {
  font-size: var(--fs-12) !important;
}

.text-sm {
  font-size: var(--fs-14) !important;
}

.text-base {
  font-size: var(--fs-16) !important;
}

.text-lg {
  font-size: var(--fs-18) !important;
}

.text-xl {
  font-size: var(--fs-20) !important;
}

.text-2xl {
  font-size: var(--fs-22) !important;
}

.text-3xl {
  font-size: var(--fs-24) !important;
}

.text-4xl {
  font-size: var(--fs-26) !important;
}

.text-5xl {
  font-size: var(--fs-28) !important;
}

.text-6xl {
  font-size: var(--fs-32) !important;
}

.text-7xl {
  font-size: var(--fs-40) !important;
}

.text-8xl {
  font-size: var(--fs-48) !important;
}

.text-9xl {
  font-size: var(--fs-52) !important;
}

.text-10xl {
  font-size: var(--fs-56) !important;
}

.text-11xl {
  font-size: var(--fs-60) !important;
}

.text-12xl {
  font-size: var(--fs-72) !important;
}

.text-13xl {
  font-size: var(--fs-96) !important;
}

.lh-0 {
  line-height: var(--lh-0) !important;
}

.lh-8 {
  line-height: var(--lh-8) !important;
}

.lh-10 {
  line-height: var(--lh-10) !important;
}

.lh-12 {
  line-height: var(--lh-12) !important;
}

.lh-14 {
  line-height: var(--lh-14) !important;
}

.lh-16 {
  line-height: var(--lh-16) !important;
}

.lh-18 {
  line-height: var(--lh-18) !important;
}

.lh-20 {
  line-height: var(--lh-20) !important;
}

.lh-22 {
  line-height: var(--lh-22) !important;
}

.lh-24 {
  line-height: var(--lh-24) !important;
}

.lh-26 {
  line-height: var(--lh-26) !important;
}

.lh-28 {
  line-height: var(--lh-28) !important;
}

.lh-30 {
  line-height: var(--lh-30) !important;
}

.lh-32 {
  line-height: var(--lh-32) !important;
}

.lh-40 {
  line-height: var(--lh-40) !important;
}

.lh-34 {
  line-height: var(--lh-34) !important;
}

.lh-80 {
  line-height: var(--lh-34) !important;
}

.line-height-8 {
  line-height: 8px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-40 {
  line-height: 40px;
}

.font-thin {
  font-weight: 100 !important;
}

.font-extralight {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-80 {
  font-size: 80px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}
