@import "colors.scss";

.icon-3xs:before {
  font-size: 12px;
}

.icon-2xs:before {
  font-size: 14px;
}

.icon-xs:before {
  font-size: 16px;
}

.icon-sm:before {
  font-size: 20px;
}

.icon-md:before {
  font-size: 25px;
}

.icon-lg:before {
  font-size: 30px;
}

.icon-2xl:before {
  font-size: 35px;
}

.icon-3xl:before {
  font-size: 40px;
}

.icon-4xl:before {
  font-size: 72px;
}

.icon-primary:before {
  color: var(--color-primary);
}

.icon-black:before {
  color: var(--color-black);
}

.icon-white:before {
  color: var(--color-gray-1);
}

.icon-red:before {
  color: var(--color-error);
}

.icon-yellow:before {
  color: var(--color-warning);
}

.icon-green:before {
  color: var(--color-success);
}

.icon-gray:before {
  color: var(--color-gray-4);
}

.icon-orange:before {
  color: var(--color-warning);
}
