/* Autofill input */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Float label */
.p-float-label > .title-float {
  color: var(--color-gray-3);
  font-size: 16px;
  font-weight: 400;
  left: 18px;
  background: var(--color-gray-55);
  z-index: 8;
  margin-top: -0.6rem;

  &:focus {
    margin-top: -1rem;
    color: var(--color-primary);
  }

  &:hover {
    margin-top: -1rem;
    color: var(--color-gray-3);
  }
}

.field__textarea {
  /* Float label */
  .p-float-label > .title-float {
    color: var(--color-gray-3);
    font-size: 16px;
    font-weight: 400;
    left: 18px;
    background: var(--color-gray-55);
    z-index: 8;
    margin-top: -0.1rem;

    &:focus {
      margin-top: -1rem;
      color: var(--color-primary);
    }

    &:hover {
      margin-top: -1rem;
      color: var(--color-gray-3);
    }
  }
}

/* Float label position */
.p-float-label input:focus ~ .title-float,
.p-float-label p-password.active ~ .title-float,
.p-float-label input.p-filled ~ .title-float,
.p-float-label textarea:focus ~ .title-float,
.p-float-label textarea.p-filled ~ .title-float,
.p-float-label .p-inputwrapper-focus ~ .title-float,
.p-float-label .p-inputwrapper-filled ~ .title-float {
  top: -0.2rem;
  font-size: 12px;
  padding: 5px 7px;
  left: 12px;
}

/* Float label position */
.p-float-label input:focus ~ .title-float,
.p-float-label p-password.active ~ .title-float,
.p-float-label input.p-filled ~ .title-float,
.p-float-label textarea:focus ~ .title-float,
.p-float-label textarea.p-filled ~ .title-float,
.p-float-label .p-inputwrapper-focus ~ .title-float,
.p-float-label .p-inputwrapper-filled ~ .title-float {
  top: -0.2rem;
  font-size: 12px;
  padding: 5px 7px;
  left: 12px;
}

.p-float-label textarea:focus ~ .title-float {
  top: -0.75rem;
}

.p-float-label textarea.p-filled ~ .title-float {
  top: -0.75rem;
}

/* Float label in focus */
.p-float-label input:focus ~ label {
  color: var(--color-primary);
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: var(--color-primary);
}

/* Input */
.p-inputtext.ng-touched.p-filled {
  border: 1px solid var(--color-gray-4);
  box-shadow: none;
}

/* Input field */
.field__item {
  width: 100%;
  position: relative;
  margin-bottom: 24px;

  &:hover {
    .ng-invalid.ng-touched {
      .p-float-label .title-float {
        color: var(--color-gray-3);
      }
    }

    .p-float-label .title-float {
      color: var(--color-gray-3);
    }
  }

  .p-float-label .p-inputtext:focus ~ label.title-float {
    color: var(--color-primary);
  }

  .p-inputtext {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    background: transparent;
    color: var(--color-gray-3);
    border-radius: 8px;
    border-color: var(--color-gray-4);

    &:enabled:focus {
      border-color: var(--color-primary) !important;
    }

    &:enabled:hover {
      border-color: var(--color-gray-3);
      box-shadow: none;
    }

    &.ng-valid + .title-float {
      top: -0.2rem;
      color: var(--color-gray-3);
      font-size: 12px;
      font-weight: 400;
      left: 12px;
      z-index: 8;
      padding: 5px 7px;
      background: var(--color-gray-55);
    }

    &:disabled + .title-float {
      top: -0.2rem;
      color: var(--color-gray-45);
      font-size: 12px;
      font-weight: 400;
      left: 12px;
      z-index: 8;
      padding: 5px 7px;
      background: var(--color-gray-55);
    }

    &:read-only + .title-float {
      top: -0.2rem;
      color: var(--color-gray-45);
      font-size: 12px;
      font-weight: 400;
      left: 12px;
      z-index: 8;
      padding: 5px 7px;
      background: var(--color-gray-55);
    }
  }

  &.has-typing, &.has-valid {
    .title-float {
      top: -0.2rem;
      color: var(--color-gray-3);
      font-size: 12px;
      font-weight: 400;
      left: 12px;
      z-index: 8;
      padding: 5px 7px;
      background: var(--color-gray-55);
    }
  }
}

/* Float label in modal */
.field--modal {
  .p-float-label > .title-float {
    color: var(--color-gray-3);
    font-size: 16px;
    font-weight: 400;
    left: 18px;
    background: var(--color-gray-5);
    z-index: 100;
    margin-top: -0.6rem;

    &:focus {
      margin-top: -1rem;
    }
  }

  &:enabled:focus {
    border-color: var(--color-primary);

    &.ng-invalid.ng-dirty + .title-float {
      color: var(--color-primary);
    }
  }

  &:enabled:hover {
    border-color: var(--color-gray-3);
    box-shadow: none;
  }
}

.field__item--error {
  width: 100%;
  margin-top: 5px;
  font-size: 11px;
  color: var(--color-error);
  position: relative;
  text-align: left;
}

.add_botton_space {
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}

/* Field item - Extras */
.field__item {
  /* Message error */
  .p-inputtext:enabled:focus {
    box-shadow: none !important;
  }

  .field__item--error {
    position: absolute;
    bottom: -20px;
    left: 0px;
    width: 100%;
    margin-top: 5px;
    font-size: 11px;
    color: var(--color-error);

    .icon-error {
      &:after {
        content: url("../../assets/icon/icon-error.svg");
        position: absolute;
        right: 16px;
        top: -37px;
        height: 16px;
        width: 19px;
        z-index: 9;
      }
    }

    &.without-icon {
      .icon-error {
        &:after {
          display: none;
        }
      }
    }

    &.textarea__events {
      .icon-error {
        &:after {
          right: 16px;
          top: -132px;
        }
      }
    }
  }

  app-show-errors.error__attributes {
    .field__item--error {
      .icon-error {
        &:after {
          top: -24px;
        }
      }
    }
  }

  app-show-errors.error__booking {
    .field__item--error {
      .icon-error {
        &:after {
          right: 40px;
        }
      }
    }
  }

  /* View password btn */
  .view--password {
    position: absolute;
    right: 16px;
    top: 13px;
    cursor: pointer;
  }

  /* Field info */
  .field__item--info {
    margin: 8px 0px 0px;
    font-size: 12px;
    line-height: 16px;

    span {
      font-size: 12px;
      line-height: 16px;
      color: var(--color-gray-1);
    }
  }

  /* Field Spinner */
  .field__item--spinner {
    position: absolute;
    right: 16px;
    top: 13px;
  }

  /* Dropdown */
  .dropdown__list {
    position: absolute;
    background: var(--color-gray-6);
    max-height: 158px;
    width: 100%;
    display: none;
    min-width: 160px;
    overflow: auto;
    box-shadow: none;
    z-index: 999;

    .dropdown__items {
      border: 1px solid var(--color-gray-55);
      color: var(--color-gray-1);
      padding: 12px 16px;
      text-decoration: none;
      cursor: pointer;
      display: block;
    }
  }

  /* Calendar */
  p-calendar {
    .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
      color: var(--color-gray-1);
      background: var(--color-gray-6);
      border-bottom: none;

      .p-datepicker-next:enabled:hover {
        background-color: var(--color-gray-55);
        box-shadow: none;
      }

      .p-datepicker-prev:enabled:hover {
        background-color: var(--color-gray-55);
        box-shadow: none;
      }
    }

    .p-datepicker.p-component {
      color: var(--color-gray-1);
      background: var(--color-gray-6);
      box-shadow: none;
    }

    .p-datepicker table td.p-datepicker-today > span.p-highlight {
      background-color: var(--color-primary);
      color: var(--color-gray-5);
    }

    .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
      background-color: var(--color-primary);
      color: var(--color-gray-5);
    }

    .p-datepicker table td > span.p-highlight {
      background-color: var(--color-primary);
      color: var(--color-gray-5);
    }
  }
}

/* Input field - Mask */
.field__item {
  &.field__item--mask {
    .p-inputmask {
      &:hover {
        & + .title-float {
          color: var(--color-gray-3);
        }
      }

      &.p-inputwrapper-focus {
        .p-inputmask {
          border-color: var(--color-primary);
        }

        & + .title-float {
          color: var(--color-primary);
        }

        & + .field__item--error {
          display: none;
        }
      }

      &.ng-invalid.ng-dirty > .p-inputmask {
        &:focus {
          border-color: var(--color-primary);
        }
      }
    }

    &.has-typing {
      .field__item--error {
        display: none;
      }

      .p-float-label > .ng-invalid.ng-dirty + label {
        color: var(--color-gray-3);
      }

      p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
        border: 1px solid var(--color-gray-4);
      }

      .p-inputmask.p-inputtext.ng-dirty.ng-invalid:enabled:focus {
        border: 1px solid var(--color-primary);
      }

      .p-float-label > .p-inputmask {
        &.p-inputwrapper-focus + .title-float {
          color: var(--color-primary);
        }
      }

      &.p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
        border: 1px solid var(--color-gray-4);
        box-shadow: none;
      }

      .p-inputmask.ng-dirty.ng-invalid:enabled:focus {
        & + label {
          color: var(--color-primary);
        }
      }
    }

    &.has-error {
      .p-inputmask {
        .p-inputmask {
          border-color: var(--color-error);
          box-shadow: none;
        }

        & + .title-float {
          color: var(--color-error);
        }
      }
    }

    &.has-filled {
      &.p-inputwrapper-focus {
        & + .title-float {
          color: var(--color-primary);
        }
      }
    }
  }
}

/* Validations input field */
.field__item {
  /* Has Typing */
  &.has-typing {
    .field__item--error {
      display: none;
    }

    .p-inputtext.ng-dirty.ng-invalid {
      border: 1px solid var(--color-gray-4);
      box-shadow: none;
    }

    .p-float-label > .ng-invalid.ng-dirty + label {
      color: var(--color-gray-3);
    }

    .p-inputtext.ng-dirty.ng-invalid:enabled:focus {
      border: 1px solid var(--color-primary);
    }

    .p-inputtext.ng-dirty.ng-invalid:enabled:focus {
      & + label {
        color: var(--color-primary);
      }
    }

    &:enabled:hover {
      .p-inputtext.ng-dirty.ng-invalid {
        border: 1px solid var(--color-primary);
      }

      .p-float-label > .ng-invalid.ng-dirty + label {
        color: var(--color-primary);
      }
    }
  }

  /* Error input */
  &.has-error {
    margin-bottom: 43px;

    .p-inputtext {
      border: 1px solid var(--color-error);

      &.ng-invalid {
        border-color: var(--color-error);
      }
    }

    .title-float {
      color: var(--color-error) !important;
      top: -0.2rem;
      font-size: 12px;
      padding: 5px 7px;
      left: 12px;
    }

    .p-dropdown {
      border: 1px solid var(--color-error);

      .p-dropdown .p-dropdown-label.p-placeholder {
        color: var(--color-error) !important;
      }

      .p-dropdown-label {
        color: var(--color-error) !important;
      }

      .p-inputtext {
        border: none;
      }

      &.ng-invalid {
        border-color: var(--color-error);
      }
    }

    .ng-invalid.ng-dirty + label {
      color: var(--color-error);
    }

    .field__item--error {
      display: block;
    }

    .field__item--info {
      margin: 24px 0px -20px;
    }

    .p-inputtext:enabled:hover {
      border-color: var(--color-error);
      box-shadow: none;
    }

    .p-inputtext:enabled:focus {
      border-color: var(--color-error) !important;
      box-shadow: none !important;

      & ~ label.title-float {
        color: var(--color-error);
      }
    }

    &:hover {
      .field__item--error {
        display: block;
      }

      .p-float-label .title-float {
        color: var(--color-error);
      }
    }
  }

  /* Valid input */
  &.has-valid {
    .p-inputtext {
      border: 1px solid var(--color-gray-4);
      box-shadow: none;
    }

    /*.p-dropdown-label.p-inputtext {
      border: none;
    }*/
    .title-float {
      color: var(--color-gray-3);
    }

    .p-float-label > .ng-invalid.ng-dirty + label {
      color: var(--color-gray-3);
    }

    .field__item--error {
      display: none;
    }
  }

  /* Filled input */
  &.has-filled {
    .p-inputtext {
      border: 1px solid var(--color-primary);
    }

    .title-float {
      color: var(--color-primary);
    }
  }

  /* unfilled input */
  &.has-unfilled {
    .p-float-label > .title-float {
      color: var(--color-gray-3);

      &:hover {
        color: var(--color-gray-3);
      }
    }

    .p-inputtext.ng-dirty.ng-invalid {
      border-color: var(--color-gray-4);
    }

    .field__item--error {
      display: none;
    }
  }

  &.has-invalid {
    margin-bottom: 32px !important;

    .p-float-label > .title-float {
      color: var(--color-error);
      background: var(--color-gray-55);
      top: 3px;
      font-size: 12px;
      font-weight: 400;
      left: 12px;
      z-index: 8;
      padding: 0px 6px;

      &:hover {
        color: var(--color-error);
      }
    }

    .p-inputtext.ng-dirty.ng-invalid {
      border-color: var(--color-error);
    }
  }
}

/* Input number */
.field__item {
  &.field--number {
    p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
      border-color: var(--color-gray-4);
    }

    .p-inputnumber-input {
      width: 100%;
      height: 48px;
      padding: 16px 16px;
      background: transparent;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px !important;

      &:enabled:hover {
        border-color: var(--color-gray-3);
        box-shadow: none;

        & + .title-float {
          color: var(--color-gray-3);
        }
      }

      &:enabled:focus {
        border-color: var(--color-primary);

        &.ng-invalid.ng-dirty + .title-float {
          color: var(--color-primary);
        }
      }
    }

    p-inputnumber {
      &.p-inputwrapper-focus {
        & + label.title-float {
          color: var(--color-primary);
          font-size: 12px;
        }
      }
    }

    &.has-error {
      &.p-inputwrapper-focus.ng-invalid {
        & + label.title-float {
          color: var(--color-error);
        }
      }

      p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
        border-color: var(--color-error);
      }

      &.field--number__increment {
        .field__item--error {
          max-width: 87%;
        }
      }

      &.field--number__increment--take-away {
        .field__item--error {
          max-width: 50%;
        }
      }
    }
  }

  &.field--number__atribute {
    max-width: 132px;

    &.p-inputwrapper-focus {
      & + label.title-float {
        color: var(--color-primary);
        font-size: 12px;
      }
    }
  }
}

/* Field calendar */
.field__item.field--calendar {
  p-calendar.p-inputwrapper-focus {
    & + label.title-float {
      color: var(--color-primary);
    }
  }
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  border-radius: 8px !important;
}

/* Input dropdown */
/*.p-dropdown-trigger-icon {
  color: var(--color-primary);
}*/

/* Textarea */
textarea {
  min-height: 100px;
}

/* Input search */
.search-container {
  max-width: 224px;
  border-radius: 8px;
  overflow: hidden;

  .p-input-icon-left > i:first-of-type {
    color: var(--color-gray-3);
    left: 16px;
  }

  .p-inputtext {
    font-size: 14px;
    color: var(--color-gray-4);
    line-height: 16px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-gray-5);
    padding: 12px 16px;
    outline: none;

    &:enabled:hover {
      border: none;
    }

    &:enabled:focus {
      box-shadow: none;
    }
  }

  .p-input-icon-left > .p-inputtext {
    padding: 8px 16px 8px 48px;
  }
}

/* Input switch */
.p-inputswitch {
  height: 1.7rem !important;
  display: flex !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--color-primary) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  background: var(--color-gray-6) !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: var(--color-gray-6) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  box-shadow: none !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.2 !important;
}

/* Input addon */
.p-inputgroup-addon {
  background: var(--color-gray-8) !important;
  color: var(--color-gray-1) !important;
  border: 1px solid var(--color-gray-3) !important;
  border-radius: 10px !important;
  margin-right: 5px !important;
}

/* Input date time */
.field--time {
  input[type="time"] {
    background: transparent;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 600;
    border: 1px solid var(--color-gray-4);
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: center;

    &::-webkit-calendar-picker-indicator {
      filter: invert(100%);
      margin-right: 8px;
    }
  }
}

/* Field hour */
.field--hour {
  max-width: 125px;
  margin-bottom: 0;

  input {
    width: 100%;
    height: 40px;
    background: transparent;
    color: var(--color-gray-1);
    border-radius: 8px;
    border-color: var(--color-gray-4);
    cursor: pointer;
    box-shadow: none;

    /*&.ng-invalid.ng-dirty {
      border-color: var(--color-error);
      box-shadow: none;

      & + .title-float {
        color: var(--color-error);
      }
    }*/

    &:enabled:focus {
      border-color: var(--color-primary);

      &.ng-invalid.ng-dirty + .title-float {
        color: var(--color-primary);
      }
    }

    &:enabled:hover {
      border-color: var(--color-gray-3);
      box-shadow: none;

      & + .title-float {
        color: var(--color-gray-3);
      }
    }
  }
}

p-inputswitch {
  .p-inputswitch .p-inputswitch-slider {
    background: var(--color-gray-4);
  }
}


.field__checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .label {
    font-size: 16px;
    line-height: 16px;
    color: var(--color-gray-1);
  }
}

.checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  cursor: pointer;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid var(--color-gray-1);
  }

  // Box hover
  &:hover + label:before {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  // Box focus
  &:focus + label:before {
    box-shadow: none;
  }

  // Box checked
  &:checked + label:before {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
  }

  // Disabled state label.
  &:disabled + label {
    color: var(--color-gray-4);
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: var(--color-gray-3);
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 3px;
    transition: all .1s ease-in;
    transform: rotate(45deg);
    height: 14px;
    width: 7px;
    display: inline-block;
    border-bottom: 2px solid var(--color-gray-6) !important;;
    border-right: 2px solid var(--color-gray-6) !important;;
  }
}

input:disabled {
  color: var(--color-gray-45) !important;
}
