@import 'src/theme/helpers/mixins';

.icon-warning {
  display: flex;
  justify-content: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// BiD Modal - General styles
.bid-modal {
  .p-dialog,
  .p-dialog-mask.p-component-overlay {
    backdrop-filter: blur(10px);
    z-index: 1001 !important;
  }

  .p-dialog-mask {
    padding: 20px;
    font-size: 14px;
  }

  @include for-breakpoint('ipad') {
    .p-dialog-mask {
      padding: 0;
    }
  }
}

.bid-modal {
  &.modal__language {
    .p-dialog,
    .p-dialog-mask.p-component-overlay {
      backdrop-filter: blur(4px);
      z-index: 1001 !important;
    }
  }
}

// BiD Modal Large
.bid-modal {
  .p-dialog {
    width: 100%;
    max-width: 552px;
    border-radius: 18px;
  }
}

.bid-modal {
  &.modal__language {
    .p-dialog {
      width: 100%;
      max-width: 90%;
      border-radius: 18px;

      @include for-breakpoint('ipad') {
        max-width: 471px;
      }

    }
  }
}

.bid-modal.add-event-code .p-dialog .p-dialog-content{
  padding: 48px 0;
}

.bid-modal .p-dialog .p-dialog-header {
  position: relative;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: var(--color-gray-5);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-image: var(--color-modal-gradient);
  }
}

.bid-modal {
  &.modal__language {
    .p-dialog .p-dialog-header {
      position: relative;
      text-align: center;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 24px 24px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      background: var(--color-gray-5);
    }
  }
}

.bid-modal {
  &.modal-show-guests {
    .p-dialog .p-dialog-content {
      overflow: hidden !important;
      background: var(--color-gray-55) !important;
      padding: 0px !important;

      @include for-breakpoint('ipad') {
        padding: 0px !important;
      }
    }
  }
}

.bid-modal {
  &.resale {
    .p-dialog .p-dialog-content {
      padding: 0 0 32px;
    }
  }
}

.bid-modal .p-dialog .p-dialog-content {
  padding: 24px 0 32px;
  background: var(--color-gray-5);

  .field__item {
    margin-bottom: 0;
  }

  .field__group {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.bid-modal {
  &.modal__language {
    .p-dialog .p-dialog-content {
      padding: 24px 0 24px;
      background: var(--color-gray-5);

      .field__item {
        margin-bottom: 0;
      }

      .field__group {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
}

.filling-space {
  width: 24px;
  height: 24px;
}

.bid-modal .p-dialog .p-dialog-header .p-dialog-header-icons {
  border-radius: 0;

  .p-dialog-header-icon {
    width: 24px !important;
    height: 24px !important;
    color: var(--color-gray-2);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .pi.pi-times {
      &:before {
        display: none;
      }

      &:after {
        content: url('../../assets/icon/circle-xmark-light.svg');
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.bid-modal__header--headtitle {
  font-family: var(--font-secondary);
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: var(--color-gray-1) !important;
  margin: 0 0 8px !important;
}

.bid-modal__header--title {
  font-family: var(--font-secondary);
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  color: var(--color-gray-1) !important;
  margin: 0 !important;
  display: flex;

  .invitationName {
    font-family: var(--font-secondary);
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: var(--color-primary) !important;
    margin: 0 0 0 4px !important;
  }

  @include for-breakpoint('ipad') {
    font-size: 20px !important;

    .invitationName {
      font-size: 20px !important;
    }
  }
}

.bid-modal__subheader--title {
  font-family: var(--font-primary) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: var(--color-gray-35) !important;
  margin: 8px 0 0 !important;
  display: flex;
}

.bid-modal__header--subtitle {
  font-family: var(--font-primary);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: var(--color-gray-3) !important;
  margin: 0 0 8px !important;
}

.bid-modal__header--headtitle-mobile {
  font-family: var(--font-secondary);
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  color: var(--color-gray-3) !important;
  margin: 0 0 8px !important;
}

.bid-modal__header--title-mobile {
  font-family: var(--font-secondary);
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: var(--color-gray-2) !important;
  margin: 0 !important;
  display: flex;

  .invitationName {
    font-family: var(--font-secondary);
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    color: var(--color-primary) !important;
    margin: 0 0 0 4px !important;
  }
}

.bid-modal__subheader--title-mobile {
  font-family: var(--font-primary) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: var(--color-gray-35) !important;
  margin: 8px 0 0 !important;
  display: flex;
}

.bid-modal .p-dialog .p-dialog-footer {
  position: relative;
  display: flex;
  flex-direction: column-reverse !important;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px 24px;
  background: var(--color-gray-5);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  @include for-breakpoint('ipad') {
    flex-direction: row !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-image: var(--color-modal-gradient)
  }

  .btn.btn__secondary {
    height: 48px;
    width: 100%;
    font-weight: 500;
    margin: 0;

    .p-button-label {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  .btn.btn__remove {
    height: 48px;
    width: 100%;
    font-weight: 500;
    margin: 0;

    .p-button-label {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  .btn.btn__primary {
    height: 48px;
    width: 100%;
    font-weight: 500;
    margin: 0;

    &.btn--one {
      width: initial;
      min-width: 184px;
    }

    .p-button-label {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
}

.bid-modal {
  &.modal__language {
    .p-dialog .p-dialog-footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px 24px 32px;
      background: var(--color-gray-5);
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;

      .btn.btn__secondary {
        height: 48px;
        width: 100%;
        font-weight: 500;
        margin: 0;

        .p-button-label {
          font-size: 16px !important;
          font-weight: 500 !important;
        }
      }

      .btn.btn__primary {
        height: 48px;
        width: 100%;
        font-weight: 500;
        margin: 0;

        &.btn--one {
          width: initial;
          min-width: 184px;
        }

        .p-button-label {
          font-size: 16px !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}

// BiD Modal Informative
.bid-modal.bid-modal__informative {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }
}

.bid-modal.bid-modal__informative {
  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }
}

.bid-modal.bid-modal__informative .p-dialog .p-dialog-content {
  padding: 0 24px;
  background: var(--color-gray-5);
}

.bid-modal.bid-modal__informative .p-dialog .p-dialog-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 40px 24px 24px;
  background: var(--color-gray-5);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;

  &:before {
    display: none;
  }
}

.bid-modal.bid-modal__warning {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }
}

.bid-modal.bid-modal__warning--small {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }

  .p-dialog-content {
    flex-direction: column;
    text-align: center;
    padding: 0 24px 32px;
  }

  .p-confirm-dialog-message {
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: var(--color-gray-1);
    margin: 32px 0 0 !important;

    .subheader {
      display: block;
      font-family: var(--font-primary);
      font-size: 14px;
      line-height: 20px;
      color: var(--color-gray-35);
      margin-top: 8px !important;
    }

    .title {
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      color: var(--color-gray-1);
      margin: 0 !important;
      display: inline-block;

      .text-primary {
        font-weight: 600;
        color: var(--color-primary) !important;
      }
    }
  }

  .p-dialog .p-dialog-footer {
    padding-top: 0;
    padding-bottom: 24px;

    &:before {
      display: none;
    }
  }
}

.bid-modal.bid-modal__small {
  .p-dialog {
    width: 100%;
    max-width: 432px;
    border-radius: 18px;
  }

  .p-dialog .p-dialog-header {
    padding: 24px 24px 0;

    &:after {
      display: none;
    }

    .p-dialog-header-icons {
      position: absolute;
      top: 24px;
      right: 24px;
      bottom: auto;
      left: auto;
      border-radius: 0;
    }
  }

  .p-dialog .p-dialog-footer {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
}

.bid-modal.bid-moda__cropimage {
  .p-dialog {
    width: 100%;
    max-width: 650px;
    border-radius: 18px;
  }
}

.bid-modal.bid-modal-guest {
  .p-dialog .p-dialog-content {
    overflow-y: initial !important;
  }
}

.p-dialog-header .p-dialog-header-icons .p-dialog-header-icon {
  border: none;
}

.p-dialog-header-icon.p-dialog-header-close {
  color: var(--color-gray-2) !important;

  .pi.pi-times {
    &:before {
      display: none;
    }

    &:after {
      content: url('../../assets/icon/circle-xmark-light.svg');
      position: absolute;
      right: 0;
      top: 0;
      width: 23px;
      height: 23px;
    }
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: var(--color-gray) !important;
  border-color: transparent !important;
  background: var(--color-gray-contrast) !important;
  box-shadow: none !important;
}

.p-dialog-header {
  padding: 32px 16px 6px;
}

.dialog-leave-event {
  width: 95vw !important;

  .p-dialog-title {
    width: 100% !important;
    font-size: 22px !important;
    line-height: 32px;
    font-weight: 600;
  }

  .p-dialog-header {
    width: 100% !important;
    color: var(--color-gray-1) !important;
  }

  &.dialog-products {
    .p-dialog-header .p-dialog-title {
      font-size: 22px !important;
      font-weight: 600;
      line-height: 32px;
    }
  }

  &.dialog-confirmation {
    width: 500px !important;
    text-align: -webkit-center;
  }

  .p-dialog-footer {
    flex-direction: row-reverse !important;
  }
}
