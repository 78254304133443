////
/// Colors
/// Init css basic colors
/// @group Colors
////

:root {
  --color-primary: #10C9BE;
  --color-primary-hover: #00A399;
  --color-primary-pressed: #12E5D9;
  --color-primary-disabled: #007770;
  --color-primary-secondary-hover: #031716;
  --color-primary-secondary-pressed: #020d0c;

  --color-gray-9: #616161;
  --color-gray-8: #434343;
  --color-gray-7: #040404;
  --color-gray-6: #101010;
  --color-gray-55: #1C1C1C;
  --color-gray-5: #2B2B2B;
  --color-gray-45: #5E5E5E;
  --color-gray-4: #747474;
  --color-gray-35: #B8B8B8;
  --color-gray-3: #D6D6D6;
  --color-gray-2: #E9E9E9;
  --color-gray-1: #F3F3F3;
  --color-gray: #373737;

  --color-gray-contrast: #282828;
  --color-gray-dark: #242424;
  --color-background: #141414;

  --color-background-ipad: hsla(0, 0%, 5%, 0.85);
  --color-shadow-5: rgba(0, 0, 0, 0.75);
  --color-shadow-4: rgba(0, 0, 0, 0.6);
  --color-shadow-35: rgba(0, 0, 0, 0.25);
  --color-shadow-3: rgba(0, 0, 0, 0.2);
  --color-shadow-2: rgba(0, 0, 0, 0.4);
  --color-shadow-1: rgba(0, 0, 0, 0.35);

  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-white-contrast: #dfdfdf;
  --color-success: #58c321;
  --color-success-dark: #0a2909;
  --color-success-background: #061f06;
  --color-warning: #FF9900;
  --color-warning-background: #1f1403;
  --color-error: #B64545;
  --color-error-hover: #cc4d4d;
  --color-sold-out: #c94e4e;
  --color-error-disabled: #6d2828;
  --color-error-dark: #2b1010;
  --color-error-pressed: #0f0707;
  --color-tags: #5046B8;
  --color-info: #5597CD;

  --color-dalay-transaction: #80B5E0;
  --color-info-background: #050c11;
  --color-credit-border-card: #3D4666;
  --color-logo-box: #03030375;
  --color-spinner: #030303;
  --color-uala: #596bc0;
  --color-uala-2: #8399ff;
  --color-violet: #3945ec;

  --color-modal: rgba(140, 140, 140, 0.35);
  --color-footer-button-fixed: rgba(43, 43, 43, 0.9);
  --color-overlay: rgba(23, 23, 23, 0.8);
  --color-dark-trans-2: rgba(16, 16, 16, 0.95);
  --color-dark-trans-1: rgba(13, 13, 13, 0.5);
  --color-dark-trans: rgba(0, 0, 0, 0.8);

  --color-hero-2: rgba(46, 46, 46, 0.8);

  --color-card-img: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.99) 100%);
  --color-linear-gradient: linear-gradient(90deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 100%);
  --color-linear-gradient-3: linear-gradient(270deg, #101010 0%, rgba(16, 16, 16, 0) 100%);
  --color-linear-gradient-2: linear-gradient(3.39deg, #151515 5.59%, #313131 98.65%);
  --color-credit-card: linear-gradient(191.68deg, #464646 6.64%, #262626 89.5%);
  --color-credit-card-active: linear-gradient(1.14deg, #0069B4 0.31%, #49A5DD 98.37%);
  --color-modal-gradient: linear-gradient(180deg, #030303, #525252);
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-primary-hover {
  color: var(--color-primary-hover) !important;
}

.text-primary-pressed {
  color: var(--color-primary-pressed) !important;
}

.text-primary-disabled {
  color: var(--color-primary-disabled) !important;
}

.text-white {
  color: var(--color-white) !important;
}

.text-white-contrast {
  color: var(--color-white-contrast) !important;
}

.text-gray-1 {
  color: var(--color-gray-1) !important;
}

.text-gray-2 {
  color: var(--color-gray-2) !important;
}

.text-gray-3 {
  color: var(--color-gray-3) !important;
}

.text-gray-35 {
  color: var(--color-gray-35) !important;
}

.text-gray-4 {
  color: var(--color-gray-4) !important;
}

.text-gray-5 {
  color: var(--color-gray-5) !important;
}

.text-gray-55 {
  color: var(--color-gray-55) !important;
}

.text-gray-6 {
  color: var(--color-gray-6) !important;
}

.text-gray-7 {
  color: var(--color-gray-7) !important;
}

.text-gray-8 {
  color: var(--color-gray-8) !important;
}

.text-gray-9 {
  color: var(--color-gray-9) !important;
}

.text-gray {
  color: var(--color-gray) !important;
}

.text-gray-contrast {
  color: var(--color-gray-contrast) !important;
}

.text-gray-dark {
  color: var(--color-gray-dark) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.text-success {
  color: var(--color-success) !important;
}

.text-success-dark {
  color: var(--color-success-dark) !important;
}

.text-error {
  color: var(--color-error) !important;
}

.text-error-hover {
  color: var(--color-error-hover) !important;
}

.text-error-disabled {
  color: var(--color-error-disabled) !important;
}

.text-error-dark {
  color: var(--color-error-dark) !important;
}

.text-warning {
  color: var(--color-warning) !important;
}

.text-yellow {
  color: var(--color-warning) !important;
}

.text-info {
  color: var(--color-info) !important;
}

.text-violet {
  color: var(--color-violet) !important;
}

.text-transparent {
  color: transparent !important;
}

.bg-gray-9 {
  background-color: var(--color-gray-9) !important;
}

.bg-gray-8 {
  background-color: var(--color-gray-8) !important;
}

.bg-gray-7 {
  background-color: var(--color-gray-7) !important;
}

.bg-gray-6 {
  background-color: var(--color-gray-6) !important;
}

.bg-gray-5 {
  background-color: var(--color-gray-5) !important;
}

.bg-gray-55 {
  background-color: var(--color-gray-55) !important;
}

.bg-gray-4 {
  background-color: var(--color-gray-4) !important;
}

.bg-gray-35 {
  background-color: var(--color-gray-35) !important;
}

.bg-gray-3 {
  background-color: var(--color-gray-3) !important;
}

.bg-gray-2 {
  background-color: var(--color-gray-2) !important;
}

.bg-gray-1 {
  background-color: var(--color-gray-1) !important;
}

.bg-success {
  background-color: var(--color-success) !important;
}

.bg-success-dark {
  background-color: var(--color-success-dark) !important;
}

.bg-error {
  background-color: var(--color-error) !important;
}

.bg-error-hover {
  background-color: var(--color-error-hover) !important;
}

.bg-error-disabled {
  background-color: var(--color-error-disabled) !important;
}

.bg-error-dark {
  background-color: var(--color-error-dark) !important;
}

.bg-warning {
  background-color: var(--color-warning) !important;
}

.bg-info {
  background-color: var(--color-info) !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}

.bg-primary-hover {
  color: var(--color-primary-hover) !important;
}

.bg-primary-pressed {
  color: var(--color-primary-pressed) !important;
}

.bg-primary-disabled {
  color: var(--color-primary-disabled) !important;
}

.bg-white {
  color: var(--color-white) !important;
}

.bg-white-contrast {
  color: var(--color-white-contrast) !important;
}

.bg-black {
  color: var(--color-black) !important;
}

.bg-gray {
  color: var(--color-gray) !important;
}

.bg-gray-contrast {
  color: var(--color-gray-contrast) !important;
}

.bg-gray-dark {
  color: var(--color-gray-dark) !important;
}

.bg-yellow {
  color: var(--color-warning) !important;
}

.bg-violet {
  color: var(--color-violet) !important;
}
