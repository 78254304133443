/* You can add global styles to this file, and also import other style files */

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import "./theme/global.scss";
@import "assets/fonts/icomoon/style.css";

// Leaflet Geosearch styling
@import "../node_modules/leaflet-geosearch/dist/geosearch.css";
@import "../node_modules/leaflet/dist/leaflet.css";

* {
  font-family: var(--font-primary);
}


.iti__country-list {
  background-color: var(--color-gray-5) !important;
}

.country-dropdown {
  border: 1px solid var(--color-gray-3) !important;
  color: var(--color-gray-1) !important;
  background-color: var(--color-gray-5) !important;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  color: var(--color-gray-3) !important;
  padding: 0 10px !important;
}

.iti {
  //margin-bottom: 24px !important;
  width: 100% !important;
}

ngx-intl-tel-input input {
  width: 100% !important;
  height: 48px !important;
  background: transparent !important;
  color: var(--color-gray-3) !important;
  border-radius: 8px !important;
  border-color: var(--color-gray-4) !important;
  border-image: none !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-size: 16px !important;
  padding-left: 125px !important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input.int-phone {
  border: 1px solid var(--color-error);
}

ngx-intl-tel-input input.int-phone:focus {
  border-color: var(--color-primary) !important;
  outline: none;
}

ngx-intl-tel-input input.int-phone::-webkit-input-placeholder {
  color: var(--color-gray-8);
}

ngx-intl-tel-input input.int-phone:-ms-input-placeholder {
  color: var(--color-gray-8);
}

ngx-intl-tel-input input.int-phone::-ms-input-placeholder {
  color: var(--color-gray-8);
}

ngx-intl-tel-input input.int-phone::placeholder {
  color: var(--color-gray-8);
}

ngx-intl-tel-input input[disabled] {
  background-color: var(--color-gray-9);
}
