.bid-badge {
  width: fit-content;
  font-family: var(--font-primary);
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  color: var(--color-gray-6);
  padding: 0px 8px;
  margin-left: 8px;
  border-radius: 6px;
  text-transform: uppercase;
  white-space: nowrap;

  &.bid-badge--green {
    color: var(--color-success);
  }

  &.bid-badge--orange {
    color: var(--color-warning);
  }

  &.bid-badge--red {
    background-color: var(--color-error);
  }

  &.bid-badge--white {
    color: var(--color-gray-3);
  }

  &.bid-badge--primary {
    color: var(--color-primary);
  }

  &.bid-badge--grey {
    color: var(--color-gray-4);
  }

  &.bid-badge--bg-green {
    background-color: var(--color-success);
    color: var(--color-gray-6) !important;
  }

  &.bid-badge--bg-orange {
    background-color: var(--color-warning);
    color: var(--color-gray-6) !important;
  }

  &.bid-badge--bg-red {
    background-color: var(--color-error);
    color: var(--color-gray-6) !important;
  }

  &.bid-badge--bg-white {
    background-color: var(--color-gray-3);
    color: var(--color-gray-6) !important;
  }

  &.bid-badge--bg-primary {
    background-color: var(--color-primary);
    color: var(--color-gray-6) !important;
  }

  &.bid-badge--bg-dark {
    background-color: var(--color-gray-55);
    color: var(--color-gray-1);
  }

  &.bid-badge--bg-gray-6 {
    background-color: var(--color-gray-6);
    color: var(--color-gray-4);
  }
}
