@import "core/core.scss";
@import "helpers/helpers.scss";
@import "modules/modules.scss";

.p-button {
  border-radius: 12px;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: var(--color-gray-8) !important;
}

.p-dropdown {
  .p-dropdown-label.p-placeholder {
    color: var(--color-gray-1) !important;
  }
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--color-primary);
}

.p-dialog .p-confirm-dialog-message {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.p-dialog .p-confirm-dialog-message .topheader {
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 24px;
  color: var(--color-gray-1);

  &.no-subheader {
    margin-bottom: 32px;
  }
}
