@import "src/theme/helpers/mixins";

.btn {
  font-family: var(--font-primary);
  text-align: center;
  cursor: pointer;

  &:disabled {
    opacity: 1 !important;
  }

  &.btn__lg {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    padding: 14.5px 55px;
    border-radius: 12px;
  }

  &.btn__md {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    padding: 11px 16px;
    border-radius: 12px;

    @include for-breakpoint('ipad') {
      width: auto;
      padding: 11px 45px;
    }

    @include for-breakpoint("ipad") {
      width: auto;
      padding: 11px 45px;
    }

    &.btn__login {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      border-radius: 12px !important;
    }

    &.btn__code {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      border-radius: 12px;
      padding: 15px 20px;
      max-width: 160px;
      min-width: 152px;

      &:disabled {
        background: none;
      }
    }
  }

  &.btn__sm {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    padding: 7px 26px;
    border-radius: 8px;
  }

  &.btn__login {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    padding: 7px 8px;
    border-radius: 10px;

    @include for-breakpoint('ipad') {
      padding: 7px 8px;
    }

  }

  &.btn__payment {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    padding: 15px 16px;
    border-radius: 8px;
    min-width: 203px;
  }

  &.btn__back {
    display: flex;
    align-items: center;
    color: var(--color-gray-1);
    background: none;
    border: none;
    outline: none;

    .icon__back {
      font-size: 18px;
      color: var(--color-gray-1);
    }

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: var(--color-gray-1);
      margin-left: 8px;
    }

    &:hover {
      color: var(--color-gray-2);
    }

    &.color-primary {
      color: var(--color-primary);

      span,
      .icon__back {
        color: var(--color-primary);
      }

      &:hover {
        color: var(--color-primary-hover);
      }
    }
  }

  &.btn__next {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 48px;
    min-width: 203px;
    max-width: 203px;
    padding: 12px 16px;
    color: var(--color-gray-6);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    position: relative;

    span {
      position: relative;
      z-index: 2;
    }

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--color-primary);
    }

    &:disabled {
      background-color: var(--color-primary-disabled);
      border: 1px solid var(--color-primary-disabled);
      color: var(--color-gray-6);
      opacity: 1 !important;

      &:hover {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6);
      }

      &:active {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6);
      }

      &:focus {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6);
        box-shadow: none !important;
      }
    }

    &:hover {
      background-color: var(--color-primary-hover);
      border: 1px solid var(--color-primary-hover);
      color: var(--color-gray-6);
    }

    &:active {
      background-color: var(--color-primary-pressed);
      border: 1px solid var(--color-primary-pressed);
      color: var(--color-gray-6);
    }

    &.active {
      &:before {
        width: 100%;
        transition: width 1s linear;
      }
    }
  }

  &.btn__primary {
    color: var(--color-gray-6);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    font-family: var(--font-primary);

    &:hover {
      background-color: var(--color-primary-hover);
      border: 1px solid var(--color-primary-hover);
      color: var(--color-gray-6) !important;
    }

    &:active {
      background-color: var(--color-primary);
      border: 1px solid var(--color-primary-hover);
      color: var(--color-gray-6) !important;
    }

    &:focus {
      background-color: var(--color-primary-pressed);
      border: 1px solid var(--color-primary-pressed);
      color: var(--color-gray-6) !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: var(--color-primary-disabled);
      border: 1px solid var(--color-primary-disabled);
      color: var(--color-gray-6) !important;
      opacity: 1 !important;

      &:hover {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6) !important;
      }

      &:active {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6) !important;
      }

      &:focus {
        background-color: var(--color-primary-disabled);
        border: 1px solid var(--color-primary-disabled);
        color: var(--color-gray-6) !important;
        box-shadow: none !important;
      }
    }
  }

  &.btn__secondary {
    color: var(--color-primary);
    background-color: transparent;
    border: 1px solid var(--color-primary);
    font-family: var(--font-primary);

    &:hover {
      background-color: var(--color-primary-secondary-hover);
      border: 1px solid var(--color-primary-hover);
      color: var(--color-primary) !important;
    }

    &:active {
      background-color: var(--color-gray-6);
      border: 1px solid var(--color-primary-hover);
      color: var(--color-primary) !important;
    }

    &:focus {
      background-color: var(-color-primary-secondary-pressed);
      border: 1px solid var(--color-primary-pressed);
      color: var(--color-primary) !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid var(--color-primary-disabled);
      color: var(--color-primary-disabled) !important;
      opacity: 1 !important;
    }
  }

  &.btn__terciary {
    color: var(--color-primary);
    background-color: transparent;
    border: none;

    &:hover {
      color: var(--color-primary-hover);
    }

    &:focus {
      color: var(--color-primary-hover);
      box-shadow: none !important;
    }

    &:disabled {
      color: var(--color-primary-disabled);
      opacity: 1 !important;
    }
  }

  &.btn__remove {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    padding: 12px 16px;
    color: var(--color-error);
    border: 1px solid var(--color-error);
    background-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: var(--color-error-dark);
      border: 1px solid var(--color-error-hover);
      color: var(--color-error) !important;
    }

    &:active {
      background-color: var(--color-error-dark);
      border: 1px solid var(--color-error-hover);
      color: var(--color-error) !important;
    }

    //&:focus {
    //  background-color: var(--color-error-dark);
    //  border: 1px solid var(--color-error-pressed);
    //  color: var(--color-error) !important;
    //  box-shadow: none !important;
    //}

    &:disabled {
      background-color: transparent;
      border: 1px solid var(--color-error-disabled);
      color: var(--color-error-disabled) !important;
      opacity: 1 !important;
    }
  }
}
