
.switch-container {
  position: relative;

  #switch {
    display: none;
  }

  .switch-label {
    position: relative;
    cursor: pointer;
    margin: 0;

    .switch-rail {
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: -12px;
      left: 16px;
      width: 39px;
      height: 24px;
      background: var(--color-gray-4);
      border-radius: 50px;
      overflow: hidden;

      .switch-slider {
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--color-gray-6);
      }
    }
  }

  #switch:checked + .switch-label {
    .switch-rail {
      transition: all 0.2s ease-in-out;
      background: var(--color-primary);

      .switch-slider {
        transition: all 0.2s ease-in-out;
        transform: translateX(14px);
      }
    }
  }
}

