@import './mixins.scss';

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: start;
}

.items-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.flex-col {
  flex-direction: column;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.mobile {
  display: block;

  @include for-breakpoint('ipad') {
    display: none;
  }
}

.desktop {
  display: none;

  @include for-breakpoint('ipad') {
    display: block;
  }
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}
